.menu-items-wrapper {
  height: 60px;
  position: fixed;
  bottom: 0;
  .ant-menu-horizontal::before,
  .ant-menu-horizontal::after {
    content: none !important;
  }
  .ant-menu-item-icon {
    font-size: 23px !important;
  }
  .ant-menu-title-content {
    display: none !important;
  }
  .ant-menu-light.ant-menu-horizontal {
    > .ant-menu-item::after,
    > .ant-menu-submenu::after {
      border-bottom: none !important;
      content: none !important;
    }
  }
}
