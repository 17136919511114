.wheel-column-container {
  position: relative;
  width: 40px;
  overflow-y: scroll;
  user-select: none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border: none;

  .wheel-column-container::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  .wheel-column-item {
    text-align: center;
    cursor: pointer;
    transition: color 0.2s;
    background: none;
    color: #666;
  }

  .wheel-column-item.selected {
    color: #000;
    font-weight: 600;
  }
}
