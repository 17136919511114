.settings-locations-wrapper {
  .locations-table {
    box-shadow: 0px 2px 8px 0px #5194d826;
  }
  .locations-table-row {
    height: 60px !important;
    cursor: pointer;
  }
  .user-unit-row-title {
    border-bottom: 1px solid #e0e0e0;
  }
  .user-unit-row {
    &:first-child {
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
    }
    border-bottom: 1px solid #e0e0e0;
    &:last-child {
      border-bottom: none;
    }
  }
}
