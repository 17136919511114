.automations-view-tabs-wrapper {
  .ant-tabs-nav::before {
    border: none;
  }

  .ant-tabs .ant-tabs-nav {
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 1px;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
  }

  .ant-tabs-nav .ant-tabs-nav-wrap {
    flex: unset;
  }
  .ant-tabs .ant-tabs-tab-btn {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
  }

  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin-left: -1px;
  }

  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
  }
}

@media (max-width: 768px) {
  .automations-view-tabs-wrapper .ant-tabs-tab {
    width: 100%;
    display: block;
    text-align: center;
  }
}

.leads-wrapper {
  .mobile-header {
    .actions-wrapper {
      flex-wrap: nowrap;

      .ant-btn {
        white-space: nowrap;
      }
    }
  }

  .automations-view-tabs-wrapper {
    width: 100%;
    padding-right: 0;

    @media (max-width: 768px) {
      width: calc(100vw - 20px);
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  .tabs-mobile-view {
    margin-bottom: 16px;

    @media (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      .actions-wrapper {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        width: 100%;

        .ant-btn {
          width: 100%;
        }
      }
    }
  }
}
