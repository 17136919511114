@import 'src/@aduvi/styles/base/colors.scss';

.header-actions-wrapper {
  @media (max-width: 768px) {
    align-items: baseline;
    justify-content: center;
    width: 217px;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;

    .header-actions-buttons {
      display: flex;
      flex-wrap: nowrap;
    }
  }

  .icon {
    cursor: pointer;
    font-size: 16px;
    color: $gray-8;
  }
}

.view-filter-card {
  .icon {
    cursor: pointer;
    font-size: 16px;
    color: $gray-8;
  }

  .filters-container {
    border: none;
    background-color: transparent;
    .ant-collapse-header {
      padding: 0;
    }
    .ant-collapse-item {
      border: none;
      margin-bottom: 8px;
    }
    label {
      display: none;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
