.header {
  .desktop-header {
    width: 100%;
    display: flex;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  .tablet-header {
    display: none;
    @media screen and (max-width: 900px) {
      display: flex;
    }
    .actions-row {
      @media screen and (max-width: 500px) {
        flex-direction: column;
      }
    }
    .header-breadcrumbs,
    .header-actions {
      @media screen and (max-width: 500px) {
        display: flex;
        justify-content: center;
        flex-grow: 1;
        margin-bottom: 10px;
      }
    }
  }
  box-shadow: 0px 2px 8px 0px #5194d826;
  position: relative;

  .header-tabs {
    .ant-tabs-top > .ant-tabs-nav,
    .ant-tabs-bottom > .ant-tabs-nav,
    .ant-tabs-top > div > .ant-tabs-nav,
    .ant-tabs-bottom > div > .ant-tabs-nav {
      margin: 0 !important;
    }
  }
}

.breadcrumb {
  ol {
    align-items: center;
  }
}
.branding-selection {
  .ant-select-selector {
    background-color: transparent;
    border: 0;
    padding-left: 0;
    box-shadow: none !important;
  }
}

.branding-list {
  width: auto !important;
}
