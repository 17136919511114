.checklist-card {
  border-radius: 10px;
  width: 100%;
  height: 100% !important;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.checklist-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.checklist-item {
  display: flex;
  gap: 0.75rem;
  align-items: flex-start;
}

.check-circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #e2e8f0;
  flex-shrink: 0;
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-completed {
  font-size: 24px;
  line-height: 24px;

  color: #4ade80;
}

.item-content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.item-title {
  font-weight: 500;
  color: #1a2b3b;
}

.item-description {
  font-size: 10px;
  color: #64748b;
}

.completed .item-title,
.completed .item-description {
  color: #94a3b8;
  text-decoration: line-through;
}
