.wheel-time-picker-container {
  position: relative;

  .highlight-band {
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  .wheel-time-picker-columns {
    display: flex;
    gap: 0;
    align-items: center;
  }
}
