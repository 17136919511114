.user-profile-popover {
  .ant-popover-content {
    margin-bottom: 10px !important;
    margin-left: 5px !important;
    border-radius: 10px;
  }

  .ant-popover-inner {
    border-radius: 10px;
  }
}

.borderless-select .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  flex-grow: 1;
  min-width: 150px;
  max-width: 100%;
}

.mobile-only-icons {
  display: none;
  gap: 16px;

  .user-menu-icon {
    font-size: 20px;
    color: grey;
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-start;
  }
}

.business-name {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
