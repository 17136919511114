.edit-job-file-card {
  margin: 5px;
  height: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .title {
    font-size: 20px;
    margin-bottom: 8px;
  }
  .description {
    font-size: 18px;
    color: #00000073;
    padding-left: 8px;
  }
}
