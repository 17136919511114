.single-field-range-picker-dropdown {
  min-width: 300px;
  min-height: 300px;
  max-width: 450px;
}

.inline-single-field-range-picker {
  min-width: 300px;
  min-height: 300px;
}

.segmented-row {
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
}

.segmented-label {
  position: relative;
  padding: 0 24px;
  text-align: center;
}

.segmented-date-info {
  font-size: 12px;
}

.calendar-and-time-wrapper {
  display: flex;
}

.time-picker-wrapper {
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.range-input {
  width: 100%;
  height: 32px;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  outline: none;
  box-shadow: none;
}

.range-input-active {
  border: 1px solid #4096ff;
}

.calendar-cell-outer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-cell-outer.in-range {
  background-color: #e6f7ff;
}

.calendar-cell-outer.start-left {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.calendar-cell-outer.end-right {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.day-label {
  position: relative;
  z-index: 1;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 50%;
  user-select: none;
}

.day-label.selected {
  background-color: #1890ff;
  color: #fff;
}
