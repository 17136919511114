.personalized-view-wrapper {
  .tabs-mobile-view {
    @media (max-width: 768px) {
      display: block;
    }
  }

  .personalized-view-tabs-wrapper {
    padding-right: 30px;
    width: 100%;
    @media (max-width: 768px) {
      display: flex;
      justify-content: left;
    }
    @media (max-width: 768px) {
      display: flex;
      justify-content: row;

      .personalized-view-tabs {
        width: calc(100vw - 20px);
      }
    }
    .personalized-view-tabs {
      line-height: 0;
    }

    .ant-tabs-nav::before {
      border: none;
    }

    .ant-tabs .ant-tabs-nav {
      margin-bottom: 10px;
      margin-top: 10px;
      padding: 1px;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
      line-height: 0;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
      border: 1px solid #d9d9d9;
      background-color: #ffffff;
    }

    .ant-tabs-nav .ant-tabs-nav-wrap {
      flex: unset;
    }
    .ant-tabs .ant-tabs-tab-btn {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
    }

    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
      margin-left: -1px;
    }

    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
      border: 1px solid #d9d9d9;
      background-color: #ffffff;
    }
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
      border: 1px solid #d9d9d9;
      background-color: #ffffff;
    }
    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .ant-col {
    font-size: inherit !important;
  }
  .ant-row {
    font-size: inherit !important;
  }
}
