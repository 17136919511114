.custom-steps {
  .ant-steps {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .ant-steps-item-container {
    display: inline-grid !important;
  }
}
