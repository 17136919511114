@import 'src/@aduvi/styles/base/colors.scss';

.edit-job-drawer-wrapper {
  .ant-drawer-body {
    padding: 0;
    overflow-x: hidden;
  }
  .ant-tabs {
    padding: 0 24px;
  }

  @media (max-width: 768px) {
    .ant-tabs {
      padding: 0 !important;
      margin: 0 5px;
    }

    .ant-tabs-content {
      padding: 0 24px;
    }
  }
  .job-tabs {
    .ant-tabs-content-holder {
      margin-left: -16px;
      margin-right: -16px;
    }
  }
  .selected-event-details {
    padding: 16px 24px;
    padding-bottom: 0;
  }
  .event-title {
    color: inherit;
    margin-bottom: 5px;
    font-size: 24px;
    font-weight: normal;
  }

  .event-title-input {
    .ant-form-item-label {
      display: none;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-input {
      border: none;
      color: white;
      background-color: rgba(255, 255, 255, 0.1607843137);
      width: 300px;
      height: 40px;
      font-size: 20px;
    }

    .ant-input::placeholder {
      color: white;
    }
  }
  .event-date,
  .event-status {
    margin-top: 4px;
    color: inherit;
  }
  .event-date-field {
    .ant-form-item-label {
      display: none;
    }

    .ant-form-item {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    .range-input {
      border: 1px solid #4096ff;
      color: white;
      background-color: rgba(255, 255, 255, 0.1607843137);
      width: 300px;
    }

    .range-input::placeholder {
      color: white;
    }
  }
  .status-select {
    label {
      color: white;
      margin-bottom: 0;
      display: block;
    }
    .ant-col {
      padding-bottom: 0 !important;
    }
    .ant-row {
      flex-wrap: nowrap !important;

      width: 200px;
    }
    .ant-form-item-row {
      flex-direction: row !important;
      align-items: center;

      @media (max-width: 768px) {
        width: 50px;
      }
    }
    .ant-form-item-control-input {
      min-height: unset;
    }
    .ant-select-arrow {
      color: white;
    }
    .ant-select {
      width: auto;
      min-width: 140px;
    }
    .ant-select-selector {
      color: white;
      background-color: transparent;
      border: none;
      margin-left: 3px;
      background-color: #ffffff29;
      .ant-select-selection-item {
        color: inherit;
      }
    }
  }
  .contact-card {
    position: relative;
    top: -25px;
  }

  .job-name-input {
    width: 100%;
    font-size: 24px;
    border: none;
    background-color: transparent;
    padding: 0;
    line-height: 24px;
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
    .ant-input {
      padding: 0;
    }
  }
  .job-name-input::placeholder {
    color: $white;
  }
  .input-column:last-child {
    flex-grow: 1 !important;
    max-width: none !important;
  }
}
